import request from '../request';

// smart-site/screen
const serviceName = '/smart-site/screen';

// 获取塔吊 平均吊重 额重
export function towerWeight(data) {
  return request(`${serviceName}/tower/chart-avg`, {
    method: 'GET',
    body: data,
  });
}
// 获取塔吊 累计高度-吊重
export function towerHeightWeight(data) {
  return request(`${serviceName}/tower/chart-total`, {
    method: 'GET',
    body: data,
  });
}
// 获取塔吊 幅度-角度区间
export function towerAngle(data) {
  return request(`${serviceName}/tower/chart-range`, {
    method: 'GET',
    body: data,
  });
}
// 获取塔吊  升降机 上机记录
export function towerBoard(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/on-board`, {
    method: 'GET',
    body: data,
  });
}
// 获取塔吊上机记录详情
export function towerBoardDetail(data) {
  return request(`${serviceName}/tower/on-board/detail`, {
    method: 'GET',
    body: data,
  });
}
// 获取塔吊 运行记录
export function towerRunning(data) {
  return request(`${serviceName}/tower/running-record`, {
    method: 'GET',
    body: data,
  });
}

/** @name 获取塔吊监控地址 */
export function deviceMonitor(id) {
  return request(`${serviceName}/tower/monitor/${id}`, {
    method: 'GET',
  });
}

// 获取塔吊 升降机 警报记录
export function towerAlarm(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/alarm/stat/list`, {
    method: 'POST',
    body: data,
  });
}
// 其他设备警报
export function otherAlarm(data, deviceGroup) {
  return request(`${serviceName}/other/${deviceGroup}/alarm/stat/list`, {
    method: 'POST',
    body: data,
  });
}
// 获取塔吊 升降机 警报记录详情
export function towerAlarmDetail(data, deviceGroup) {
  return request(`${serviceName}/${deviceGroup}/alarm/record/list`, {
    method: 'POST',
    body: data,
  });
}
// 其他设备警报详情
export function otherAlarmDetail(data, deviceGroup) {
  return request(`${serviceName}/other/${deviceGroup}/alarm/record/list`, {
    method: 'POST',
    body: data,
  });
}
// 其他设备警报的点位列表
export function otherAlarmPoint(data, deviceGroup) {
  return request(`${serviceName}/other/${deviceGroup}/alarm/stat/trend/point`, {
    method: 'POST',
    body: data,
  });
}
// 获取塔吊 塔吊状态
export function towerStatus(deviceId) {
  return request(`${serviceName}/tower/status/${deviceId}`, {
    method: 'GET',
  });
}
// 获取深基坑 监测点位
export function pitPointsAlarm(data) {
  return request(`${serviceName}/deep/alarm/point`, {
    method: 'POST',
    body: data,
  });
}
// 获取深基坑 趋势
export function pitTrend(data) {
  return request(`${serviceName}/deep/trend/statics`, {
    method: 'GET',
    body: data,
  });
}
// 获取深基坑 其他设备警报
export function pitAlarmList(data, deviceGroup = 'deep') {
  return request(`${serviceName}/${deviceGroup}/alarm/record/list`, {
    method: 'POST',
    body: data,
  });
}

// 获取升降机 载重速度区间
export function liftWeightSpeed(data) {
  return request(`${serviceName}/elevator/chart-range`, {
    method: 'GET',
    body: data,
  });
}
// 升降机 累计高度/层高
export function liftHeightFloor(data) {
  return request(`${serviceName}/elevator/height-chart`, {
    method: 'GET',
    body: data,
  });
}
// 获取升降机 实时状态
export function liftStatus(deviceId) {
  return request(`${serviceName}/elevator/status/${deviceId}`, {
    method: 'GET',
  });
}
// 获取升降机 载重人数
export function liftWeightPeople(data) {
  return request(`${serviceName}/elevator/total-record`, {
    method: 'GET',
    body: data,
  });
}
// 其他设备 设备统计
export function otherDeviceTotal(projectId) {
  return request(`${serviceName}/other/alarm/device/stat/${projectId}`, {
    method: 'GET',
  });
}
// 其他设备 趋势
export function otherDeviceTrend(data, deviceGroup) {
  return request(`${serviceName}/other/${deviceGroup}/alarm/stat/trend`, {
    method: 'POST',
    body: data,
  });
}
// 其他设备 设备统计排行
export function otherDeviceRank(data) {
  return request(`${serviceName}/other/alarm/rank/list`, {
    method: 'POST',
    body: data,
  });
}
// 其他设备 设备警报趋势
export function otherAlarmTrend(data) {
  return request(`${serviceName}/other/alarm/stat/trend`, {
    method: 'POST',
    body: data,
  });
}
