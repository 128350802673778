<template>
  <a-table
    style="height: 5rem"
    @change="handlePagination"
    :columns="columns"
    :dataSource="datasource"
    :pagination="pagination"
    :loading="loading"
    :scroll="{ x: '100%', y: 'max-content' }"
    :transformCellText="({ text }) => text || '-'"
    row-key="pkId"
  ></a-table>
</template>

<script>
import { Component, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import AsyncSelect from '@/components/async-select';
import { getUpDownLog } from '@/services/screen/jiangsu/common';
import PaginationMixin from '../../../project/mixin/pagination';
import { mixins } from 'vue-class-component';
import moment from 'moment';
import OssImage from '@/views/screen/components/oss-image.vue';

@Component({ components: { EmptyContent, AsyncSelect } })
export default class DeviceLog extends mixins(PaginationMixin) {
  @Prop({ type: String, default: '' }) deviceId;

  searchParams = { deviceId: '' };
  api(params) {
    return getUpDownLog(params, 'tower');
  }
  get columns() {
    return [
      {
        title: '序号',
        width: 70,
        customRender: (text, row, index) => {
          const { current, pageSize } = this.pagination;
          return (current - 1) * pageSize + index + 1;
        },
      },
      {
        title: '设备名称',
        dataIndex: 'deviceName',
        width: 180,
        ellipsis: true,
      },
      {
        title: '设备码',
        dataIndex: 'deviceCode',
        width: 180,
        ellipsis: true,
      },
      {
        align: 'left',
        title: '抓拍图片',
        dataIndex: 'capture',
        width: 180,
        customRender: text => (text ? <OssImage ossPath={text} /> : ''),
      },
      {
        title: '司机名称',
        dataIndex: 'driverName',
        width: 150,
        ellipsis: true,
      },
      {
        title: '司机身份证号',
        dataIndex: 'driverIdentityNumber',
        width: 250,
        ellipsis: true,
      },
      {
        title: '上下机',
        dataIndex: 'type',
        width: 100,
        ellipsis: true,
        customRender: text => {
          const success = <div class={this.$style.successText}>上机</div>;
          const error = <div class={this.$style.errorText}>下机</div>;

          return ['-', success, error][text] ?? '-';
        },
      },
      {
        title: '上下机时间',
        dataIndex: 'faceTime',
        width: 180,
        ellipsis: true,
        customRender: text => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
    ];
  }

  created() {
    this.searchParams.deviceId = this.deviceId;
  }
}
</script>

<style lang="less" module>
.successText {
  color: var(--screen-success);
}
.errorText {
  color: var(--screen-error);
}
</style>
