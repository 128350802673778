<template>
  <div class="grid grid-cols-2" :class="$style.gridWrap">
    <div class="">
      <div :class="$style.label">设备码</div>
      <div :class="$style.value">{{ data.deviceCode }}</div>
    </div>
    <div class="">
      <div :class="$style.label">生产厂家</div>
      <div :class="$style.value">{{ data.productionCompany }}</div>
    </div>
    <div class="">
      <div :class="$style.label">项目备案号</div>
      <div :class="$style.value">{{ data.projectNumber }}</div>
    </div>
    <div class="">
      <div :class="$style.label">产权单位</div>
      <div :class="$style.value">{{ data.propertyCompany }}</div>
    </div>
    <div>
      <div :class="$style.label">产权编号</div>
      <div :class="$style.value">{{ data.propertyNumber }}</div>
    </div>
    <div>
      <div :class="$style.label">使用单位</div>
      <div :class="$style.value">{{ data.usedCompany }}</div>
    </div>
    <div class="col-span-2">
      <div :class="$style.label">设备资质证书</div>
      <div :class="$style.value" id="imageWrap">
        <div class="flex flex-wrap" style="gap: 0.08rem;">
          <div
            :class="$style.image"
            v-for="(item, index) in data.certificate"
            :key="index"
            @click="reviewImage(index)"
          >
            <OssImage :path="item.value" basePath="/oss/oss"></OssImage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import OssImage from './oss-image.vue';
import { Preview } from '@triascloud/x-components';

@Component({ components: { EmptyContent, OssImage } })
export default class DeviceInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) data;

  reviewImage(index) {
    const urls = Array.from(
      document.querySelector('#imageWrap').querySelectorAll('img') ?? [],
    ).map(({ src }) => src);
    Preview.createModal({
      urls,
      index,
    });
  }
}
</script>

<style lang="less" module>
.label {
  color: rgba(216, 225, 243, 0.5);
}

.value {
  color: #e1e8f5;
}

.wrap {
  font-size: 0.12rem;
  width: 4.32rem;
}

.avatar {
  width: 0.64rem;
  height: 0.64rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--bg);
}
.name {
  color: #37d7db;
  font-size: 0.14rem;
  padding-top: 0.08rem;
}

.gridWrap {
  row-gap: 0.16rem;
}

.image {
  width: 1.12rem;
  height: 1.12rem;
  background: #071c38;
  margin-top: 0.08rem;
  cursor: pointer;
}
</style>
