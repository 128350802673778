<template>
  <div :class="$style.statusWrap" class="h-full flex flex-col items-center">
    <div
      class="w-full flex items-center justify-between"
      :class="$style.wrapHeader"
    >
      <div class="flex items-center gap-rem-16">
        <a-select
          @change="handleChange"
          v-model="deviceId"
          :options="deviceOption"
          style="width: 2rem;"
        ></a-select>
        <span :class="[$style.endTime]">最后更新时间：{{ lastTime }}</span>
      </div>
      <span :class="$style.deviceId">设备码：{{ deviceCode }}</span>
    </div>
    <div :class="$style.animation3d">
      <Animation3d
        :rotateDirect="Number(indicator.rotationAngle.count)"
        :distance="Number(indicator.margin.count)"
      />
    </div>

    <div class="flex flex-col gap-y-rem-16" :class="[$style.floatBox]">
      <div @click="showDeviceInfo">设备信息</div>
      <div @click="showDriverInfo">司机信息</div>
      <div @click="showDeviceLog">上下机记录</div>
    </div>

    <div class="grid grid-cols-8 gap-rem-16" :class="$style.statusGridWrap">
      <div
        :class="[
          $style.statusItem,
          'flex flex-col items-center justify-center',
        ]"
        v-for="(item, key) in warningList"
        :key="key"
      >
        <span :class="$style.statusValue">{{ item.value }}</span>
        <span :class="$style.statusLabel">{{ item.text }}</span>
      </div>
    </div>
    <div
      class="grid grid-cols-5 gap-x-rem-16 gap-y-rem-16"
      :class="$style.indicatorWrap"
    >
      <div
        :class="$style.indicator"
        class="flex justify-between items-center"
        v-for="(item, index) in indicator"
        :key="index"
      >
        <div :class="$style.leftLabel">
          <AliIcon :type="item.icon" :class="$style.iconStyle" />
          <div :class="[$style.text]">{{ item.text }}</div>
        </div>
        <div :class="[$style.textValue]" v-if="item.unit !== undefined">
          <span>{{
            (item.format ? item.format(item.count) : item.count) ?? '-'
          }}</span>
          <span :class="[$style.unit]">{{ item.unit }}</span>
        </div>
        <div :class="[$style.textStatus]" v-else>
          <span>{{
            (item.format ? item.format(item.count) : item.count) ?? '-'
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { towerStatus } from '@/services/screen/safety-monitor';
import AliIcon from '@/components/ali-icon';
import VideoPlayer from '@/components/video-player/index.vue';
import Animation3d from '@/views/screen/project/safety/tower/animation-3d.vue';

import { createModal } from '@triascloud/x-components';
import DriverInfo from '../components/driver-info.vue';
import { nowSize } from '@/utils/common';
import DeviceInfo from '../components/device-info.vue';
import DeviceLog from './device-log.vue';
import { getDeviceLists } from '@/services/screen/jiangsu/vehicle';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import {
  getTowerDriverDetail,
  getTowerDeviceDetail,
} from '@/services/screen/jiangsu/common';
import moment from 'moment';
import { Emitter } from '@/views/screen/components/screen-utils';
import { PublicSize } from '@/views/green-construction/components/utils';

@Component({
  components: {
    AliIcon,
    VideoPlayer,
    Animation3d,
  },
})
export default class TowerStatus extends Vue {
  deviceGroup = deviceTypeEnum[pageTypeEnum.towerCrane].deviceGroup;

  indicator = {
    height: {
      text: this.$t('detail.height'),
      icon: 'tc-icon-gaodu',
      count: null,
      unit: 'm',
    },
    margin: {
      text: '幅度',
      icon: 'tc-icon-fudu',
      count: null,
      unit: 'm',
    },
    liftingWeight: {
      text: '吊重',
      icon: 'tc-icon-diaozhong',
      count: null,
      unit: 't',
    },
    camber: {
      text: '倾角',
      icon: 'tc-icon-qingjiao',
      count: null,
      unit: '',
      format: text => `${text > 0 ? text : '-'}°`,
    },
    rotationAngle: {
      text: '回转角度',
      icon: 'tc-icon-huizhuanjiaodu',
      count: null,
      unit: '',
      format: text => `${text > 0 ? text : '-'}°`,
    },
    windSpeed: {
      text: '风速',
      icon: 'tc-icon-fengsu1',
      count: null,
      unit: 'm/s',
    },
    moment: {
      text: '起重力矩',
      icon: 'tc-icon-lijubaifenbi',
      count: null,
      unit: 'KN·m',
    },
    speed: {
      text: '运行速度',
      icon: 'tc-icon-yunhangsudu',
      count: null,
      unit: 'm/s',
    },
    torquePercentage: {
      text: '力矩百分比',
      icon: 'tc-icon-lijubaifenbi',
      count: null,
      unit: '%',
    },
    loadPercentage: {
      text: '载荷百分比',
      icon: 'tc-icon-zaizhongliang',
      count: null,
      unit: '%',
    },
  };
  openflag = false;
  warningColor = ['#FCFFFF;', '#FEBD7D', '#FA5151'];
  warningText = ['正常', '预警', '报警'];
  warningList = {
    liftingWeightStatus: {
      text: '起重量',
      value: 0,
    },
    inclinationStatus: {
      text: '倾角状态',
      value: 0,
    },
    regionalLimitStatus: {
      text: '区域限位',
      value: 0,
    },
    windSpeedStatus: {
      text: '风速状态',
      value: 0,
    },
    amplitudeOutwardLimitStatus: {
      text: '向外限位',
      value: 0,
    },
    amplitudeInwardLimitStatus: {
      text: '向内限位',
      value: 0,
    },
    heightLimitStatus: {
      text: '高度限位',
      value: 0,
    },
    groupTowerLimitStatus: {
      text: '群塔限位',
      value: 0,
    },
  };
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }

  /** 司机信息 */
  driverDetail = {};
  async getTowerDriverDetail() {
    this.driverDetail = await getTowerDriverDetail(this.deviceId);
  }
  /** 设备信息 */
  deviceDetail = {};
  async getTowerDeviceDetail() {
    this.deviceDetail = await getTowerDeviceDetail(this.deviceId);
  }

  init() {
    this.getDeviceStatus();
    this.getTowerDriverDetail();
    this.getTowerDeviceDetail();
  }

  async mounted() {
    await this.getDeviceLists();
    await this.init();
    Emitter.$on('refresh', this.init);
  }
  beforeDestroy() {
    Emitter.$off('refresh', this.init);
  }

  deviceStatusData = {};
  async getDeviceStatus() {
    try {
      const res = (await towerStatus(this.deviceId)) ?? {};
      this.deviceStatusData = res;
      for (const key in this.indicator) {
        const val = res[key] ?? '-';
        this.indicator[key].count = val === -1 ? '-' : val;
      }

      for (const key in this.warningList) {
        this.warningList[key].value =
          ['-', '正常', '预警', '报警'][res[key]] ?? '-';
      }
    } catch {
      return false;
    }
  }

  showDeviceInfo() {
    createModal(() => <DeviceInfo data={this.deviceDetail} />, {
      wrapClassName: 'screen-modal',
      title: '设备信息',
      maskClosable: false,
      width: nowSize(532),
    });
  }

  showDriverInfo() {
    createModal(() => <DriverInfo data={this.driverDetail} />, {
      wrapClassName: 'screen-modal',
      title: '司机信息',
      maskClosable: false,
      width: nowSize(532),
    });
  }

  showDeviceLog() {
    createModal(
      () => (
        <DeviceLog deviceId={this.deviceId} deviceOption={this.deviceOption} />
      ),
      {
        wrapClassName: 'screen-modal',
        title: '塔吊司机上下机记录',
        maskClosable: false,
        width: PublicSize.LargeLogModalSize,
      },
    );
  }

  deviceId = '';
  get deviceOption() {
    return this.deviceList.map(item => ({
      label: item.deviceName,
      value: item.pkId,
    }));
  }
  deviceList = [];
  get deviceCode() {
    return (
      this.deviceList.find(e => this.deviceId === e.pkId)?.deviceCode ?? '-'
    );
  }
  get lastTime() {
    const lastDataTime = this.deviceStatusData.lastUpdateTime;
    return lastDataTime
      ? moment(lastDataTime).format('YYYY-MM-DD HH:mm:ss')
      : '-';
  }
  handleChange() {
    Emitter.$emit('deviceChange', this.deviceId);
    this.init();
  }
  async getDeviceLists() {
    const res = await getDeviceLists({
      deviceGroup: this.deviceGroup.toLocaleUpperCase(),
      idxProjectId: this.projectId,
    });
    this.deviceList = res;
    const [{ value }] = this.deviceOption;
    this.deviceId = value;
    Emitter.$emit('deviceChange', this.deviceId);
  }
}
</script>

<style lang="less" module>
.statusWrap {
  padding: 0.16rem;
  position: relative;
  user-select: none;
  .unloadImg {
    height: 4rem;
    width: 8rem;
    margin-top: 0.4rem;
  }
  .floatBox {
    position: absolute;
    right: 0;
    top: 0.72rem;
    div {
      writing-mode: vertical-lr;
      letter-spacing: 5px;
      padding: 0.13rem 0.08rem 0.08rem 0.08rem;
      background: #223142;
      cursor: pointer;
      border-radius: 0.08rem 0 0 0.08rem;
      font-size: 0.12rem;
    }
  }
}
.indicator {
  width: 1.5rem;
  height: 0.6rem;
  aspect-ratio: 143/ 56;
  padding: 0.08rem;
  background: url('~@/assets/images/theme/card2-bg.svg') center / 100% 100%;
}
.indicatorWrap {
  margin-top: 0.2rem;
}
.timeText {
  margin-top: 0.18rem;
  color: #9ac0d9ff;
  font-size: 0.12rem;
}
.wrapHeader {
  // margin-bottom: auto;
  height: 0.32rem;
}
.textValue,
.textStatus {
  text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
  font-size: 0.16rem;
  color: #fff;
}

.textValue {
  font-size: 0.24rem;
  .unit {
    font-size: 0.12rem;
  }
}
.iconStyle {
  font-size: 0.24rem;
  color: #fff;
}
.leftLabel {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  color: #9ac0d9;
  .text {
    width: 0.36rem;
    line-height: 1.2;
    letter-spacing: 0.03rem;
    margin-left: 0.1rem;
  }
}

.deviceId {
  color: #9ac0d9;
  font-size: 0.14rem;
}
.endTime {
  color: #d8e1f3;
  font-size: 0.12rem;
}
.animation3d {
  width: 8rem;
  height: 4rem;
  margin-top: 0.16rem;
}
.statusItem {
  width: 0.62rem;
  height: 0.64rem;
  background: linear-gradient(
    270deg,
    rgba(118, 197, 255, 0.3) 0%,
    rgba(118, 197, 255, 0.06) 41%,
    rgba(118, 197, 255, 0.06) 60%,
    rgba(118, 197, 255, 0.3) 100%
  );
}
.statusLabel {
  color: #9ac0d9;
  font-size: 0.12rem;
  padding-top: 0.02rem;
}
.statusValue {
  color: var(--screen-font-3, #fcffff);
  font-size: 0.16rem;
  text-shadow: 0px 0px 7px #1f69c8, 0px 0px 10px #7db6ff;
}
.statusGridWrap {
  margin-top: 0.26rem;
}
</style>
