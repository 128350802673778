<template>
  <div :class="$style.chart" ref="zbar">
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <EmptyContent />
    </div>
    <div v-else ref="barChart" :class="$style.canvas"></div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import { nowSize } from '@/utils/common.js';
import { Emitter } from '@/views/screen/components/screen-utils';
import { crossStorageModule } from '@/enum/store';
import theme from '@/utils/theme/theme';

@Component({
  components: {
    EmptyContent,
  },
})
export default class BarsZoom extends Vue {
  @Prop({ type: String, default: '4.4rem' }) chartWidth;
  @Prop({ type: String, default: '2.4rem' }) chartHeight;
  @Prop({ type: Array, default: () => [] }) chartData;
  /**
   * @Description 是否展示lagend
   */
  @Prop({ type: Boolean, default: false }) showLegend;
  /**
   * @Description 是否显示多个Y轴
   */
  @Prop({ type: Boolean, default: false }) isMultipleY;
  @Prop({ type: Array, default: () => [] }) yName;
  @Prop({ type: String, default: '1%' }) chartBottom;
  @Prop({ type: String, default: '10%' }) chartTop;
  @Prop({ type: String, default: '1%' }) chartLeft;
  @Prop({ type: String, default: '5%' }) chartRight;
  @Prop({ type: Boolean, default: true }) containLabel;
  @Prop({ type: String, default: 'day' }) xInterValType;
  @Prop({
    type: Array,
    default: () => [
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#3BA272',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ],
  })
  colorArray;

  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  changeSkin() {
    this.initChart();
  }

  @Watch('chartData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal[0]?.data.length) {
      this.isNoData = false;
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.isNoData = true;
    }
  }
  chartResize() {
    setTimeout(() => {
      const rect = this.$refs.zbar.getBoundingClientRect();
      const opt = {
        width: rect.width,
        height: rect.height,
      };
      if (this.myChart) {
        this.myChart.resize(opt);
      }
    }, 100);
  }
  mounted() {
    this.initChart();
    Emitter.$on('fullscreen', this.chartResize);
  }
  beforeDestroy() {
    Emitter.$off('fullscreen', this.chartResize);
  }
  isNoData = true;
  get dataZoom() {
    return [
      {
        type: 'slider',
        show: true,
        start: 0,
        end: 100,
        xAxisIndex: [0],
        // zoomLock: true, // 不能缩放
        brushSelect: false, // 不能鼠标点击刷选
        height: nowSize(8), // 组件高度
        left: nowSize(10), // 左边的距离
        right: nowSize(30), // 右边的距离
        bottom: nowSize(0), // 右边的距离
        fillerColor: 'rgba(151, 67, 254, 0.8)',
        borderColor: 'rgba(151, 67, 254, 0.5)',
        backgroundColor: 'rgba(151, 67, 254, 0.2)',
        showDataShadow: false, // 是否显示数据阴影 默认auto
        showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
        handleIcon:
          'path://M-292,322.2c-3.2,0-6.4-0.6-9.3-1.9c-2.9-1.2-5.4-2.9-7.6-5.1s-3.9-4.8-5.1-7.6c-1.3-3-1.9-6.1-1.9-9.3c0-3.2,0.6-6.4,1.9-9.3c1.2-2.9,2.9-5.4,5.1-7.6s4.8-3.9,7.6-5.1c3-1.3,6.1-1.9,9.3-1.9c3.2,0,6.4,0.6,9.3,1.9c2.9,1.2,5.4,2.9,7.6,5.1s3.9,4.8,5.1,7.6c1.3,3,1.9,6.1,1.9,9.3c0,3.2-0.6,6.4-1.9,9.3c-1.2,2.9-2.9,5.4-5.1,7.6s-4.8,3.9-7.6,5.1C-285.6,321.5-288.8,322.2-292,322.2z',
        filterMode: 'filter',
      },
    ];
  }
  myChart = null;
  initChart() {
    if (!this.myChart) {
      const el = this.$refs.barChart;
      if (!el) return;
      this.myChart = echarts.init(el);
    }
    const zoom = this.dataZoom;
    const length = this.chartData[0]?.data.length;
    if (length === 0) return;
    if (length > 7) {
      zoom[0].show = true;
      const num = Math.round((7 / length) * 10000) / 100.0;
      zoom[0].end = Math.floor(num);
    } else {
      zoom[0].show = false;
      zoom[0].start = 0;
    }
    const dayObj = {
      minute: 'HH:mm',
      hour: 'HH:mm',
      day: 'MM-DD',
      month: 'YYYY-MM',
      year: 'YYYY',
    };
    const s = dayObj[this.xInterValType];
    const xData = this.chartData[0].time;
    const themeMode = theme['dark'];
    const options = {
      color: this.colorArray,
      grid: {
        left: this.chartLeft,
        right: this.chartRight,
        bottom: this.chartBottom,
        top: this.chartTop,
        containLabel: this.containLabel,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: params => this.toolTip(params),
        backgroundColor: themeMode.mainBg,
        textStyle: {
          color: themeMode.font,
          fontSize: nowSize(14),
        },
        extraCssText: 'border-radius:5px;border-color: var(--primary-fade-80)',
        padding: [nowSize(10)],
        position: (pos, params, el, elRect, size) => {
          const obj = { top: 10 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        },
      },
      dataZoom: zoom,
      xAxis: {
        type: 'category',
        data: xData,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          color: themeMode.font,
          formatter: params => dayjs(+params).format(s),
        },
      },
      yAxis: [
        {
          type: 'value',
          name: this.yName[0],
          axisLabel: {
            color: themeMode.font,
          },
          splitLine: {
            lineStyle: {
              color: themeMode.border,
            },
          },
        },
      ],
      series: [],
    };
    this.chartData[0].data.forEach(v => {
      const o = {
        name: v.name,
        type: v.type || 'bar',
        yAxisIndex: v.yAxisIndex,
        stack: v.stack || '',
        barMaxWidth: '20',
        data: v.data,
      };
      options.series.push(o);
    });
    if (this.showLegend) {
      const legendData = this.chartData[0].data.map(v => v.name);
      options['legend'] = {
        type: 'scroll',
        // icon: 'roundRect',
        bottom: 0,
        data: legendData,
        textStyle: {
          color: themeMode.font,
        },
        pageTextStyle: {
          color: themeMode.font,
        },
      };
    }
    if (this.isMultipleY) {
      const o = {
        name: this.yName[1],
        alignTicks: true,
        axisLabel: {
          color: themeMode.font,
        },
        splitLine: {
          lineStyle: {
            color: themeMode.border,
          },
        },
        type: 'value',
      };
      options.yAxis.push(o);
    }
    this.myChart.setOption(options, true);
  }
  toolTip(params) {
    let result = `<div class="event-chart-box">`;
    params.forEach((item, idx) => {
      const dayType = {
        minute: 'YYYY-MM-DD HH:mm',
        hour: 'YYYY-MM-DD HH:mm',
        day: 'YYYY-MM-DD',
        month: 'YYYY-MM',
        year: 'YYYY',
      };
      const time = this.dayjs(+item.name).format(dayType[this.xInterValType]);
      const unit = item.data.unit;
      const str1 = `<div>
									${time}
								</div>`;
      const str2 = `
          			<div class="warn-label">
            			<span class="color-label" style="background-color: ${item.color}"></span>
									<span>
										累计${item.seriesName}：${params[idx].value}${unit}
									</span>
          			</div>
        			`;
      if (idx === 0) {
        result += str1 + str2;
      } else {
        result += str2;
      }
    });
    return result;
  }
}
</script>
<style lang="less" module>
.chart {
  // height: calc(100% - 0.3rem);
  height: 100%;
  padding: 0.16rem;
  .canvas {
    height: 100%;
  }
  :global {
    .event-chart-box {
      .color-label {
        display: inline-block;
        margin-right: 0.06rem;
        border-radius: 0.1rem;
        width: 0.1rem;
        height: 0.1rem;
      }
    }
  }
}
</style>
