<template>
  <div :class="$style.warningDetail">
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <EmptyContent />
    </div>
    <div v-else>
      <div style="height: 1.8rem;">
        <div v-for="(item, idx) in warningList" :key="idx" :class="$style.list">
          <div :class="$style.number">
            {{ idx + 1 }}
          </div>
          <div :class="$style.time">
            {{ item.typeName }}
          </div>
          <div :class="$style.reason">
            <span :class="$style.text" @click="toDetail(item.type)">
              {{ item.count }}
            </span>
            次
          </div>
        </div>
      </div>
      <div v-if="warningListTotal > 0" :class="$style.page">
        {{ warningListTotal }} 条
        <a-pagination
          simple
          :current="pagination.current"
          :default-current="1"
          :defaultPageSize="5"
          :total="warningListTotal"
          @change="handlePagination"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon';
import { Emitter } from '@/views/screen/components/screen-utils';
import { towerAlarm } from '@/services/screen/safety-monitor';
import { createFormModal } from '@triascloud/x-components';
import AlarmDetail from './alarm-detail.vue';
import EmptyContent from '@/components/empty-content/empty-content.vue';

@Component({
  components: {
    AliIcon,
    EmptyContent,
  },
})
export default class AlarmRecord extends Vue {
  @Prop({ type: String, default: 'tower' }) type;

  get projectId() {
    return this.$route.params.projectId;
  }
  mounted() {
    this.getList();
    Emitter.$on('selectDevice', this.changeDevice);
    Emitter.$on('changeDayEnum', this.changeDayEnumTab);
    Emitter.$on('changeDayRange', this.changeDayRangeTab);
  }
  beforeDestroy() {
    Emitter.$off('selectDevice', this.changeDevice);
    Emitter.$off('changeDayEnum', this.changeDayEnumTab);
    Emitter.$off('changeDayRange', this.changeDayRangeTab);
  }
  selectedDevice = '';
  changeDevice(val) {
    this.selectedDevice = val;
    this.pagination.current = 1;
    this.getList();
  }

  changeDayEnum = 'CURRENT_DAY';
  changeDayEnumTab(val) {
    this.changeDayEnum = val;
    this.pagination.current = 1;
    if (this.selectedDevice) {
      this.getList();
    }
  }
  changeDayRange = {
    startTime: '',
    endTime: '',
  };
  changeDayRangeTab(time) {
    if (time && time.startTime) {
      this.changeDayRange = time;
    } else {
      this.changeDayRange = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    if (this.selectedDevice) {
      this.getList();
    }
  }
  formateTime(t) {
    const time = t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '-';
    return this.$t('safetyMonitor.recently') + `：${time}`;
  }
  isNoData = false;
  warningList = [];
  totalList = [];
  warningListTotal = 0;
  async getList() {
    if (!this.selectedDevice) {
      this.totalList = [];
      this.warningList = [];
      this.warningListTotal = 0;
      this.isNoData = true;
      return;
    }
    const params = {
      projectId: this.projectId,
      days: this.changeDayEnum,
      deviceId: this.selectedDevice,
      // deviceId: '1594971990373060609',
      query: {
        current: this.pagination.current,
        size: 30,
      },
    };
    if (this.changeDayRange.startTime) {
      params.days = 'CUSTOM_DAY';
      params.startTime = this.changeDayRange.startTime;
      params.endTime = this.changeDayRange.endTime;
    }
    try {
      const res = await towerAlarm(params, this.type);
      if (res.length === 0) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
        this.totalList = res;
        this.warningList = res.slice(0, 5);
        this.warningListTotal = res.length;
      }
    } catch {
      return false;
    }
  }

  pagination = {
    current: 1,
    total: 0,
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  handlePagination(page) {
    this.pagination.current = page;
    const start = (page - 1) * 5;
    const end = page * 5;
    this.warningList = this.totalList.slice(start, end);
  }
  async toDetail(type) {
    try {
      await createFormModal(
        () => (
          <AlarmDetail
            type={type}
            pageType={this.type}
            projectId={this.projectId}
            deviceId={this.selectedDevice}
            changeDayEnum={this.changeDayEnum}
            changeDayRange={this.changeDayRange}
          />
        ),
        {
          width: this.$size(700) + 'px',
          title: this.$t('safetyMonitor.statisticalDetails'),
        },
      );
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.warningDetail {
  font-size: 0.14rem;
  height: calc(100% - 0.3rem);
  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 0.15rem;
    border-bottom: 0.01rem solid var(--border);
    padding: 0 0 0.08rem 0;
  }
  .number {
    margin: 0 0.1rem;
  }
  .time {
    width: 3.8rem;
  }
  .reason {
    width: 0.8rem;
    .text {
      color: var(--primary);
      cursor: pointer;
    }
  }
  .page {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
