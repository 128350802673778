<template>
  <div class="flex gap-rem-24 w-full" :class="$style.wrap">
    <div class="flex flex-col items-center">
      <div :class="[$style.avatar]">
        <OssImage
          class="w-full h-full"
          basePath="/oss/oss"
          :path="data.avatar[0]?.value"
          type="avatar"
        ></OssImage>
      </div>
      <span :class="[$style.name]">{{ data.state }}</span>
    </div>
    <div class="flex-1 grid grid-cols-2" :class="$style.gridWrap">
      <a
        href="http://58.213.147.240:7001/QLSXHY_TY/MattersCenter/Pages/Housing.aspx?"
        target="_blank"
        class="col-span-2"
        :class="$style.verification"
        >司机资质验证</a
      >
      <div>
        <div :class="$style.label">姓名</div>
        <div :class="$style.value">{{ data?.fullName || '-' }}</div>
      </div>
      <div>
        <div :class="$style.label">持证状态</div>
        <div :class="$style.value">
          {{ data?.hasCertificate || '-' }}
        </div>
      </div>
      <div>
        <div :class="$style.label">身份证号</div>
        <div :class="$style.value">{{ data?.idCard || '-' }}</div>
      </div>
      <div>
        <div :class="$style.label">证件号</div>
        <div :class="$style.value">
          {{ data?.certificates[0]?.certificateNo ?? '-' }}
        </div>
      </div>
      <div>
        <div :class="$style.label">司机驾龄</div>
        <div :class="$style.value">
          {{
            data?.certificates[0]?.certificateHoldingTime
              ? data?.certificates[0]?.certificateHoldingTime + '年'
              : '-'
          }}
        </div>
      </div>
      <div class="col-span-2">
        <div :class="$style.label">证件图片</div>
        <div :class="$style.value">
          <div class="flex flex-wrap" style="gap: 0.08rem;" id="imageWrap">
            <div
              :class="$style.image"
              v-for="(item, index) in data.certificates[0]?.certificatePictures"
              :key="index"
              @click="reviewImage(index)"
            >
              <OssImage
                :path="item.value"
                basePath="/oss/oss"
                type="pic"
              ></OssImage>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import XOssImage from '@triascloud/x-components/dist/components/oss-image/oss-image';
import OssImage from './oss-image.vue';
import { Preview } from '@triascloud/x-components';

@Component({ components: { EmptyContent, XOssImage, OssImage } })
export default class DriverInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) data;

  reviewImage(index) {
    const urls = Array.from(
      document.querySelector('#imageWrap').querySelectorAll('img') ?? [],
    ).map(({ src }) => src);
    Preview.createModal({
      urls,
      index,
    });
  }
}
</script>

<style lang="less" module>
.label {
  color: rgba(216, 225, 243, 0.5);
}

.value {
  color: #e1e8f5;
}

.wrap {
  font-size: 0.12rem;
  width: 4.32rem;
}

.avatar {
  width: 0.64rem;
  height: 0.64rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--bg);
}
.name {
  color: #37d7db;
  font-size: 0.14rem;
  padding-top: 0.08rem;
}

.gridWrap {
  row-gap: 0.16rem;
  .verification {
    color: #22caf8;
  }
}

.image {
  width: 1.12rem;
  height: 1.12rem;
  margin-top: 0.08rem;
  cursor: pointer;
}
</style>
