<template>
  <div :class="['h-full', 'grid', 'grid-cols-2', $style.wrapGap]">
    <LayoutCard :title="$t('safety.deviceStatus')">
      <TowerStatus />
    </LayoutCard>

    <div class="flex flex-col gap-rem-16 overflow-hidden">
      <div>
        <LayoutCard title="塔吊平面图">
          <template #action>
            <ali-icon
              type="tc-icon-zhankai"
              @click="handleFullScreen"
            ></ali-icon>
          </template>
          <div :class="$style.planViewWrap">
            <PlanViewCanvas isRotation ref="planviewRef" />
          </div>
        </LayoutCard>
      </div>

      <div class="flex-1 grid grid-cols-2 gap-rem-16 overflow-hidden">
        <LayoutCard title="预警/报警类型">
          <template #action>
            <range-picker
              @ok="v => handleSearchTime(v, '1')"
              v-if="alertSelectValue === 'CUSTOM_DAY'"
              style="transform: translateX(0.09rem)"
            />
            <a-select
              :options="alertSelectOption"
              style="width: 1rem;transform: translateX(0.09rem)"
              v-model="alertSelectValue"
              @change="alertSelectChange"
            ></a-select>
          </template>
          <div class="flex flex-col h-full overflow-hidden">
            <div class="flex justify-around" :class="$style.alertTitle">
              <div class="flex items-center gap-rem-8">
                <span :class="$style.alertLabel">总预警</span>
                <span :class="$style.alertValue">{{ wranTotal }}</span>
              </div>
              <div class="flex items-center gap-rem-8">
                <span :class="$style.alertLabel">总报警</span>
                <span :class="$style.alertValue">{{ alarmTotal }}</span>
              </div>
            </div>
            <div class="flex-1 overflow-hidden">
              <PieChart
                left="10%"
                right="10%"
                :chart-data="alertAnalyzeChartData"
                :labelOption="labelOption"
              ></PieChart>
            </div>
          </div>
        </LayoutCard>

        <LayoutCard :title="['设备运行', '吊钩监控']">
          <template #action="{index}">
            <range-picker
              @ok="v => handleSearchTime(v, '2')"
              v-if="+index === 0 && runingTimeSelectValue === 'CUSTOM_DAY'"
              style="transform: translateX(0.09rem)"
            />
            <a-select
              v-if="+index === 0"
              :options="daysOption"
              v-model="runingTimeSelectValue"
              @change="runingTimeSelectChange"
              style="transform: translateX(0.09rem)"
            ></a-select>
            <a-select
              v-if="+index === 1"
              :options="monitoringOption"
              v-model="monitoringValue"
              @change="monitoringChange"
            ></a-select>
          </template>

          <div class="h-full overflow-hidden" style="padding: 0 0.1rem;">
            <LineChart
              :show-legend="true"
              :chart-data="runingTimeChartOption"
            />
          </div>
          <div class="h-full overflow-hidden">
            <VideoView
              v-if="videoConfig"
              :url="videoConfig.url"
              :accessToken="videoConfig.token"
              ref="videoRef"
              :showOperation="false"
            ></VideoView>
          </div>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import LineChart from '@/views/screen/e-charts/line-chart';
import BarsZoom from '@/views/screen/e-charts/bars-zoom';
import FallsBar from '@/views/screen/e-charts/falls-bar';
import RunningRecord from '@/views/screen/project/safety/tower/running-record';
import AlarmRecord from '@/views/screen/project/safety/tower/alarm-record';
import TowerStatus from '@/views/screen/project/safety/tower/tower-status';
import { flexDpr } from '@/assets/js/flex-dpr';
import { LayoutCard, LayoutSelect } from '../../../layout';
import PlanViewCanvas from './tower-canvas.vue';
import PieChart from '@/views/screen/e-charts/pie-chart.vue';
import { nowSize } from '@/utils/common';
import AliIcon from '@/components/ali-icon/index.vue';
import { createModal } from '@triascloud/x-components';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';
import { safetyTypeNnalysis } from '@/services/screen/jiangsu/safety-monitoring';
import {
  getMonitoringList,
  getTowerRuningTime,
} from '@/services/screen/jiangsu/common';
import moment from 'moment';
import { Emitter } from '@/views/screen/components/screen-utils';
import { getLiveAddress } from '@/services/device-manage/video-surveillance';
import VideoView from '@/components/video-view';
import RangePicker from '@/views/screen/components/range-picker.vue';

flexDpr();
@Component({
  components: {
    LineChart,
    BarsZoom,
    FallsBar,
    RunningRecord,
    AlarmRecord,
    TowerStatus,
    LayoutCard,
    LayoutSelect,
    PlanViewCanvas,
    PieChart,
    AliIcon,
    VideoView,
    RangePicker,
  },
})
export default class TowerIndex extends Vue {
  deviceGroup = deviceTypeEnum[pageTypeEnum.towerCrane].deviceGroup;

  data = {
    alertData: {},
    runingTimeData: {
      dataList: [],
      times: [],
    },
    deviceList: [],
    deviceId: '',
  };
  daysOption = [
    {
      label: '自定义',
      value: 'CUSTOM_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: '近15天',
      value: 'FIFTEEN_DAY',
    },
  ];
  get projectId() {
    return this.$store.state.screen.projectId;
  }
  mounted() {}

  initFetch() {
    this.getSafetyTypeNnalysis();
    this.getTowerRuningTime();
    this.getDustMonitoringList();
  }

  // 预/报警类型分析
  alertSelectValue = 'SEVEN_DAY';
  alertSelectOption = [
    {
      label: '自定义',
      value: 'CUSTOM_DAY',
    },
    {
      label: this.$t('screen.currentDay'),
      value: 'CURRENT_DAY',
    },
    {
      label: this.$t('screen.sevenDay'),
      value: 'SEVEN_DAY',
    },
    {
      label: this.$t('screen.thirtyDay'),
      value: 'THIRTY_DAY',
    },
  ];
  alertData = [];
  get wranTotal() {
    const format = val => (+val > 0 ? +val : 0);
    return this.alertData
      .filter(item => +item.keyType === 1)
      .reduce((a, b) => a + format(b.count), 0);
  }
  get alarmTotal() {
    const format = val => (+val > 0 ? +val : 0);
    return this.alertData
      .filter(item => +item.keyType === 2)
      .reduce((a, b) => a + format(b.count), 0);
  }
  get alertAnalyzeChartData() {
    const data = this.alertData;
    const format = val => (+val > 0 ? +val : 0);
    return data.map(item => ({
      value: format(item.count),
      name: item.typeName,
    }));
  }
  get labelOption() {
    const total = this.alertAnalyzeChartData.reduce((a, b) => a + b.value, 0);
    return {
      show: true,
      position: 'center',
      formatter: `{a|总}\n{b|${total}}`,
      rich: {
        a: {
          color: '#D8E1F3',
          fontSize: nowSize(14),
        },
        b: {
          color: '#fff',
          textShadowColor: '#7DB6FF',
          textShadowBlur: 8,
          fontSize: nowSize(20),
          padding: [nowSize(8), 0, 0, 0],
        },
      },
    };
  }
  async getSafetyTypeNnalysis(startTime = undefined, endTime = undefined) {
    const res = await safetyTypeNnalysis({
      projectId: this.projectId,
      days: this.alertSelectValue,
      deviceId: this.deviceId,
      startTime,
      endTime,
    });
    this.alertData = res;
  }
  alertSelectChange() {
    this.alertSelectValue !== 'CUSTOM_DAY' && this.getSafetyTypeNnalysis();
  }

  // 设备运行时长
  runingTimeSelectValue = 'SEVEN_DAY';
  runingTimeData = {
    dataList: [],
    times: [],
  };
  get runingTimeChartOption() {
    const { times, dataList } = this.runingTimeData;
    return [
      {
        time: times.map(e => moment(e).valueOf()),
        data: dataList?.map(item => ({
          name: item.tag,
          data: item.values.map(value => ({
            value: +value === -1 ? 0 : value,
            unit: 's',
          })),
        })),
      },
    ];
  }
  async getTowerRuningTime(startTime = undefined, endTime = undefined) {
    this.runingTimeData = await getTowerRuningTime({
      days: this.runingTimeSelectValue,
      projectId: this.projectId,
      deviceId: this.deviceId,
      startTime,
      endTime,
    });
  }
  runingTimeSelectChange() {
    this.runingTimeSelectValue !== 'CUSTOM_DAY' && this.getTowerRuningTime();
  }

  // 吊钩可视化（监控）
  monitoringList = [];
  monitoringValue = '';
  get videoConfig() {
    return this.liveAddressToken[this.monitoringValue];
  }
  get monitoringOption() {
    return this.monitoringList.map(({ deviceName, pkId }) => ({
      label: deviceName,
      value: pkId,
    }));
  }
  liveAddressToken = {};
  async getLiveAddress() {
    if (!this.monitoringValue) return;
    if (this.liveAddressToken[this.monitoringValue]) return;
    const res = await getLiveAddress({
      deviceId: this.monitoringValue,
      channelNo: '',
    });
    this.$set(this.liveAddressToken, this.monitoringValue, res);
    return res;
  }
  async getDustMonitoringList() {
    const res = await getMonitoringList({
      projectId: this.projectId,
      deviceGroup: this.deviceGroup,
      deviceId: this.deviceId,
    });
    this.monitoringList = res;
    this.monitoringValue = res[0]?.pkId;
    this.getLiveAddress();
  }
  async monitoringChange() {
    await this.getLiveAddress();
    await this.$nextTick();
    this.$refs.videoRef?.changeVideoLink(this.videoConfig.url);
  }

  // 时间筛选
  handleSearchTime(time, type) {
    const startTime = this.$moment(time[0]).valueOf();
    const endTime = this.$moment(time[1]).valueOf();
    if (type === '1') {
      this.getSafetyTypeNnalysis(startTime, endTime);
    } else if (type === '2') {
      this.getTowerRuningTime(startTime, endTime);
    }
  }

  // 选中设备发生改变
  deviceId = '';
  deviceSelectChange(deviceId) {
    this.deviceId = deviceId;
    this.videoConfig = {};
    this.getLiveAddress();
    this.initFetch();
    this.refresh();
  }

  // 每30S刷新事件
  timer = null;
  refresh() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      Emitter.$emit('refresh');
      this.refresh();
    }, 30000);
  }

  handleFullScreen() {
    createModal(
      () => (
        <div
          class={'w-full flex justify-center items-center'}
          style={'aspect-ratio: 16/9;'}
        >
          <PlanViewCanvas isModal isRotation />
        </div>
      ),
      {
        width: '80vw',
        wrapClassName: 'konva-wrapper',
      },
    );
  }

  created() {
    Emitter.$on('deviceChange', this.deviceSelectChange);
  }
  beforeDestroy() {
    Emitter.$off('deviceChange', this.deviceSelectChange);
    clearTimeout(this.timer);
  }
}
</script>

<style lang="less" module>
.wrapGap {
  gap: 0.16rem;
  overflow: hidden;
}
.alertTitle {
  padding: 0.08rem;
  font-family: 'YouSheBiaoTiHei';
  .alertLabel {
    color: rgba(255, 255, 255, 0.5);
  }

  .alertValue {
    font-size: 0.24rem;
  }
}
:global(.konva-wrapper .ant-modal-body) {
  margin: 0;
  padding: 0;
}
.planViewWrap {
  height: 45vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
