<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { towerAlarmDetail } from '@/services/screen/safety-monitor';
@Component()
export default class BoardRecord extends Vue {
  @Prop({ type: String, default: '' }) changeDayEnum;
  @Prop({ type: String, default: '' }) type;
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) projectId;
  @Prop({ type: String, default: '' }) deviceId;
  @Prop({ type: Object, default: () => {} }) changeDayRange;

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  loading = false;
  mounted() {
    this.getTableList();
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.time'),
        dataIndex: 'alarmTime',
        width: 200,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: this.$t('safety.type'),
        dataIndex: 'subTypeName',
        width: 130,
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('safety.alarmDetail'),
        dataIndex: 'reason',
        ellipsis: true,
      },
    ];
  }
  tableData = [];
  async getTableList() {
    const params = {
      projectId: this.projectId,
      days: this.changeDayEnum,
      deviceId: this.deviceId,
      subType: this.type,
      query: {
        current: this.pagination.current,
        size: this.pagination.pageSize,
      },
    };
    if (this.changeDayRange.startTime) {
      params.days = 'CUSTOM_DAY';
      params.startTime = this.changeDayRange.startTime;
      params.endTime = this.changeDayRange.endTime;
    }
    try {
      const { records, current, total } = await towerAlarmDetail(
        params,
        this.pageType,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.total = total;
    } catch {
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>

<style scoped></style>
