<template>
  <div :class="$style.warningDetail">
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <EmptyContent />
    </div>
    <div v-else>
      <div style="height: 1.8rem;margin-left: 8px;">
        <div :class="$style.list">
          <a-timeline>
            <a-timeline-item
              v-for="(item, idx) in warningList"
              :key="`${item.pkId}${idx}`"
            >
              <div>{{ formateTime(item) }}</div>
              <div :class="$style.content">
                <div :class="$style.type">
                  {{ $t('detail.liftingWeight') }}：{{
                    formateValue(item.liftingWeight)
                  }}{{ $t('unit.ton') }}
                </div>
                <div :class="$style.reason">
                  {{ $t('detail.maxTorquePercentage') }}：{{
                    formateValue(item.maxTorquePercentage)
                  }}
                </div>
              </div>
            </a-timeline-item>
          </a-timeline>
        </div>
      </div>
    </div>
    <div v-if="warningListTotal > 0" :class="$style.page">
      {{ warningListTotal }} 条
      <a-pagination
        simple
        :current="pagination.current"
        :default-current="1"
        :defaultPageSize="4"
        :total="warningListTotal"
        @change="handlePagination"
      />
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import AliIcon from '@/components/ali-icon';
import { Emitter } from '@/views/screen/components/screen-utils';
import { towerRunning } from '@/services/screen/safety-monitor';
import EmptyContent from '@/components/empty-content/empty-content.vue';

@Component({
  components: {
    AliIcon,
    EmptyContent,
  },
})
export default class RunningRecord extends Vue {
  get projectId() {
    return this.$route.params.projectId;
  }
  mounted() {
    this.getList();
    Emitter.$on('selectDevice', this.changeDevice);
    Emitter.$on('changeDayEnum', this.changeDayEnumTab);
    Emitter.$on('changeDayRange', this.changeDayRangeTab);
  }
  beforeDestroy() {
    Emitter.$off('selectDevice', this.changeDevice);
    Emitter.$off('changeDayEnum', this.changeDayEnumTab);
    Emitter.$off('changeDayRange', this.changeDayRangeTab);
  }
  selectedDevice = '';
  changeDevice(val) {
    this.selectedDevice = val;
    this.pagination.current = 1;
    this.getList();
  }

  changeDayEnum = 'CURRENT_DAY';
  changeDayEnumTab(val) {
    this.changeDayEnum = val;
    this.pagination.current = 1;
    if (this.selectedDevice) {
      this.getList();
    }
  }
  changeDayRange = {
    startTime: '',
    endTime: '',
  };
  changeDayRangeTab(time) {
    if (time && time.startTime) {
      this.changeDayRange = time;
    } else {
      this.changeDayRange = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    if (this.selectedDevice) {
      this.getList();
    }
  }
  formateTime(t) {
    let endTime;
    if (t.endTime - t.startTime > 24 * 3600 * 1000) {
      endTime = this.dayjs(t.endTime).format('YYYY/MM/DD HH:mm:ss');
    } else {
      endTime = this.dayjs(t.endTime).format('HH:mm:ss');
    }
    const startTime = this.dayjs(t.startTime).format('YYYY/MM/DD HH:mm:ss');
    return `${startTime}-${endTime}`;
  }
  formateValue(v) {
    return v !== -1 ? v : '-';
  }
  isNoData = false;
  warningList = [];
  warningListTotal = 0;
  async getList() {
    const params = {
      idxProjectId: this.projectId,
      searchDay: this.changeDayEnum,
      idxDeviceId: this.selectedDevice,
      size: 4,
      current: this.pagination.current,
    };
    try {
      const { records, total } = await towerRunning(params);
      if (records.length === 0) {
        this.isNoData = true;
      } else {
        this.isNoData = false;
        this.warningList = records;
        this.warningListTotal = total;
      }
    } catch {
      return false;
    }
  }

  pagination = {
    current: 1,
    total: 0,
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  handlePagination(page) {
    this.pagination.current = page;
    this.getList();
  }
}
</script>

<style lang="less" module>
.warningDetail {
  font-size: 0.14rem;
  height: calc(100% - 0.3rem);
  .list {
    margin-top: 0.1rem;
    height: 100%;
    .content {
      display: flex;
    }
  }
  .number {
    margin: 0 0.1rem;
  }
  .time {
    width: 2.1rem;
  }
  .type {
    width: 2rem;
  }
  .reason {
    width: 1.2rem;
  }
  .page {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  :global {
    .ant-timeline-item-head-blue {
      box-shadow: 0 0 0 0.02rem var(--primary-fade-60);
    }
    .ant-timeline-item {
      padding: 0 0 0.05rem;
    }
  }
}
</style>
