import { Component, Vue, Watch } from 'vue-property-decorator';
@Component
export default class PaginationMixin extends Vue {
  @Watch('searchParams', { deep: true })
  searchChange() {
    this.search();
  }
  loading = false;
  datasource = [];
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: false,
    showSizeChanger: true,
    size: 'small',
    pageSizeOptions: ['10', '20', '50', '100'],
    showTotal: total => `总数 ${total} `,
  };

  search() {
    this.pagination.current = 1;
    this.fetch();
  }

  handlePagination({ current, pageSize }) {
    this.pagination.current = current;
    this.pagination.pageSize = pageSize;
    this.fetch();
  }

  async fetch() {
    this.loading = true;
    const { current, pageSize: size } = this.pagination;
    try {
      const { records, total } = await this.api({
        current,
        size,
        ...(this.searchParams ?? {}),
      });

      this.datasource = records;
      this.pagination.total = total;
    } catch (error) {
      window.console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
