<template>
  <div :class="$style.wrap">
    <div ref="container3d" :class="$style.canvas"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {},
})
export default class Animation3d extends Vue {
  @Prop({ type: Number, default: 0 }) rotateDirect; //吊臂旋转角度 0-1080
  @Prop({ type: Number, default: 0 }) distance; //吊钩移动距离 0-70
  @crossStorageModule.State('skin') skin;

  mounted() {
    this.isDarkMode = this.skin.mode === 'dark';
    this.displayLiftTower();
  }

  scale = window.innerWidth / 1920;

  async displayLiftTower() {
    this.scene = new THREE.Scene();
    // 设置场景背景色为透明色
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    // 图像比例2:1
    this.renderer.setSize(800 * this.scale, 400 * this.scale);
    this.renderer.domElement.style.backgroundColor = 'transparent';
    this.$refs.container3d.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(45, 2, 1, 500);
    this.camera.position.set(0, 18, 48);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 4);
    directionalLight.position.set(0, 80, 60);
    this.scene.add(directionalLight);

    this.gltf = await this.loadTowerModel();
    this.scene.add(this.gltf.scene);
    // this.addBackground();
    this.animate();
  }

  animate() {
    requestAnimationFrame(this.animate);
    const topPart = this.gltf.scene.children.find(
      item => item.name === 'Circle71',
    );
    const towerHooks = topPart.children.find(
      item => item.name === 'Rectangle987',
    );
    let isRotatePlus = true;
    let isHookPlus = true;
    const curTime = new Date().getTime();
    const curDirect = ((Math.abs(this.rotateDirect) % 360) * Math.PI) / 180;
    const curHook =
      Math.abs(this.distance) < 70 ? Math.abs(this.distance) * 20 + 745 : 2100;

    if (this.directPre !== undefined && this.directPre !== curDirect) {
      isRotatePlus = this.directPre < curDirect;
      this.roateStopTime = 0;
    }

    if (this.hookPre !== undefined && this.hookPre !== curHook) {
      isHookPlus = this.hookPre < curHook;
      this.isMoveStop = false;
    }
    if (!this.roateStopTime) {
      if (isRotatePlus) {
        if (topPart.rotation.z < curDirect) {
          topPart.rotation.z += 0.006;
        } else {
          this.directPre = curDirect;
          this.roateStopTime = curTime;
        }
      } else {
        if (topPart.rotation.z > curDirect) {
          topPart.rotation.z -= 0.006;
        } else {
          this.directPre = curDirect;
          this.roateStopTime = curTime;
        }
      }
    } else if (
      !this.isMoveStop &&
      this.roateStopTime &&
      curTime - this.roateStopTime > 500 //停顿0.5秒后移动动吊钩
    ) {
      if (isHookPlus) {
        if (towerHooks.position.y < curHook) {
          towerHooks.position.y += 2.5;
        } else {
          this.isMoveStop = true;
          this.hookPre = curHook;
        }
      } else {
        if (towerHooks.position.y > curHook) {
          towerHooks.position.y -= 2.5;
        } else {
          this.isMoveStop = true;
          this.hookPre = curHook;
        }
      }
    }

    if (this.isDarkMode !== (this.skin.mode === 'dark')) {
      this.isDarkMode = this.skin.mode === 'dark';
      this.skyboxMesh.material = this.getSkyBoxMaterials();
      this.maskBoxMesh.material = this.getMaskBoxMaterials();
    }
    this.renderer.render(this.scene, this.camera);
  }

  loadTowerModel() {
    const loader = new GLTFLoader();
    return new Promise((resolve, reject) => {
      loader.load(
        '/3d-model/lift-tower.glb',
        gltf => {
          resolve(gltf);
        },
        undefined,
        error => {
          reject(error);
        },
      );
    });
  }
  getSkyBoxMaterials() {
    const textureLoader = new THREE.TextureLoader();
    return [
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x353535 : 0xffffff,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x353535 : 0xffffff,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x353535 : 0xffffff,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x6e6e6e : 0xf5f5f5,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x353535 : 0xffffff,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        map: textureLoader.load(
          this.isDarkMode
            ? '/3d-model/images/ft.png'
            : '/3d-model/images/ft.png',
        ),
        side: THREE.DoubleSide,
      }),
    ];
  }
  //遮住吊塔底座
  getMaskBoxMaterials() {
    return [
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x6e6e6e : 0xf5f5f5,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x6e6e6e : 0xf5f5f5,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x6e6e6e : 0xf5f5f5,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x6e6e6e : 0xf5f5f5,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x6e6e6e : 0xf5f5f5,
        side: THREE.DoubleSide,
      }),
      new THREE.MeshBasicMaterial({
        color: this.isDarkMode ? 0x6e6e6e : 0xf5f5f5,
        side: THREE.DoubleSide,
      }),
    ];
  }
  addBackground() {
    const skyboxGeometry = new THREE.BoxGeometry(200, 100, 200);
    this.skyboxMesh = new THREE.Mesh(skyboxGeometry, this.getSkyBoxMaterials());
    this.skyboxMesh.position.set(0, 15, 0);
    this.scene.add(this.skyboxMesh);

    const maskBox = new THREE.BoxGeometry(8, 8, 8);
    this.maskBoxMesh = new THREE.Mesh(maskBox, this.getMaskBoxMaterials());
    this.maskBoxMesh.position.set(0, -3.2, 0);
    this.scene.add(this.maskBoxMesh);
  }
}
</script>
<style lang="less" module>
//@import url(); 引入公共css类
.wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .canvas {
    background: url('/3d-model/images/ft.png') center / contain no-repeat;
  }
}
</style>
