<template>
  <div class="w-full h-full flex items-center justify-center">
    <template v-if="path">
      <XOssImage
        class="w-full h-full"
        :basePath="basePath"
        :ossPath="path"
      ></XOssImage>
    </template>
    <template v-else>
      <img
        class="w-full h-full"
        v-if="type == 'avatar'"
        src="@/assets/images/default-avatar.png"
        alt=""
      />

      <img
        :class="$style.picWrap"
        style="object-fit: contain;"
        v-if="type == 'pic'"
        src="@/assets/images/screen/default-img.png"
        alt=""
      />
    </template>
  </div>
</template>
<script>
import XOssImage from '@triascloud/x-components/dist/components/oss-image/oss-image';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: { XOssImage } })
export default class OssImage extends Vue {
  /** @name oss路径 */
  @Prop({ type: String, default: '' }) path;
  /**
   * @name 图片类型
   * @type {'avatar' | 'pic'}
   */
  @Prop({ type: String, default: 'pic' }) type;
  @Prop({ type: String, default: '/oss/oss' }) basePath;
}
</script>
<style lang="less" module>
.picWrap {
  width: 70%;
}
</style>
