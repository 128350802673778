import request from '../../request';

const serviceName = '/smart-site/screen';

// 预警/报警类型分析
export function safetyTypeNnalysis(body) {
  return request(`${serviceName}/tower/alarm/alarmStaticsByTowerCrane`, {
    method: 'POST',
    body,
  });
}
